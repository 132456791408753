.status-bar {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "project message tray";
    padding: 0 1rem;

    .status-bar-project {
        grid-area: project;
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    .status-bar-message {
        grid-area: message;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
    }

    .status-bar-tray {
        grid-area: tray;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
    }

    select {
        padding: 0;
        cursor: pointer;
        background-color: unset;
        border: 0;

        // hide default arrow
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-overflow: "";
        &::-ms-expand {
            display: none;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .button-with-icon {
        font-size: 14pt;
    }

    // Reduce height of react-select component
    .react-select__control {
        min-height: unset;
        overflow-y: hidden;

        .react-select__value-container,
        .react-select__input-container,
        .react-select__indicator {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    // Hide second line of text when showing the label as the selected value
    .react-select__single-value .second-line {
        display: none;
    }
}
