.details-table {
    max-width: 100%;
    margin: 0;

    td {
        white-space: nowrap;
        padding: 0.1rem 0.5rem;

        & > * {
            align-self: center;
        }

        color: var(--bs-secondary-color);
        &:first-child {
            color: unset;
        }

        .button-with-icon {
            height: 1.5rem !important;
        }
    }
}
