.create-clip-modal {
    fieldset {
        &:disabled {
            opacity: 0.5;

            input,
            .react-select__control {
                cursor: default !important;
            }
        }

        .advanced-res-inputs {
            display: grid;
            grid-template-columns: 1fr auto 1fr;

            .invalid-feedback {
                grid-column: 1/4;
            }
        }
    }
}
