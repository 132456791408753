.detail-table-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    .table-responsive {
        width: 100%;
        margin: 0;
    }
}

.detail-table {
    background-color: transparent;
}
