.import-wizard-options {
    input[type="number"] {
        width: min(100%, 80px);
    }
}

.import-wizard {
    .page-header {
        font-size: 16pt;
        padding-left: 0;
        padding-right: 0;
    }

    .page-content {
        margin: 0;
    }

    button {
        transition-property: background-color, color, opacity;
        transition-duration: 300ms;

        &:disabled {
            opacity: 0.5;
        }
    }

    .footer {
        transition: opacity 300ms;

        button {
            transition-duration: 0ms;
        }
    }
}
