@use "sass:list" as list;

@use "bootstrap" as *;

$COLOURS: (
    // BOOTSTRAP OVERWRITES
    "--bs-body-color": (hsl(210, 11%, 15%), hsl(210, 11%, 97%)),
    "--bs-body-bg": (hsl(210, 11%, 97%), hsl(240, 10%, 8%)),
    "--bs-secondary-bg": (hsl(210, 11%, 94%), hsl(240, 10%, 11%)),
    "--bs-secondary-hover-highlight": (rgba(120, 120, 120, 0.2), rgba(52, 58, 64, 0.6)),
    "--bs-tertiary-bg": (hsl(210, 11%, 90%), hsl(240, 10%, 15%)),
    // OTHER
    "--ks-button-text-color": (hsl(0, 0%, 30%), hsl(0, 0%, 30%)),
    "--timeline-sprite": (hsl(39, 100%, 50%), hsl(39, 80%, 45%)),
    "--timeline-sprite-debug": (hsl(345, 100%, 50%), hsl(345, 80%, 45%)),
    "--toast-warning-header": (hsl(56, 100%, 76%), hsl(60, 90%, 46%)),
    "--video-control-button-bg": (hsl(0, 0%, 90%), hsl(0, 0%, 70%)),
    "--video-control-button-bg-hover": (hsl(0, 0%, 80%), hsl(0, 0%, 60%)),
    "--clr-checkered-pattern-filter": (hsla(0, 0%, 95%, 90%), hsla(240, 5%, 10%, 85%)),
    "--ks-border-secondary-color": (rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.1))
);

@each $variable, $light-and-dark in $COLOURS {
    :root {
        #{$variable}: list.nth($light-and-dark, 1);
        color-scheme: light;
    }

    [data-bs-theme="dark"] {
        #{$variable}: list.nth($light-and-dark, 2);
        color-scheme: dark;
    }
}

code {
    color: #e01a76;
}
