svg.timeline-d3-chart {
    background-color: var(--bs-tertiary-bg);
    position: relative;
    overflow: hidden;

    text {
        fill: var(--bs-body-color) !important;
    }
    .mouse-line {
        shape-rendering: crispEdges;
    }

    .sprite {
        fill: var(--timeline-sprite);
    }
    .sprite-debug {
        fill: var(--timeline-sprite-debug);
    }

    .label {
        opacity: 0.75;
    }

    .label-background {
        height: 18px;
        rx: 3px;
        ry: 3px;

        &.clip-label {
            fill: rgba(50, 50, 150, 1);
        }

        &.tag-label {
            fill: rgba(113, 0, 0, 1);
        }
    }

    .label-icon {
        fill: rgb(255, 255, 255);
        filter: drop-shadow(0px 0px 3px black);
    }

    .timelineHoverBackground {
        fill: var(--bs-tertiary-bg);
        opacity: 0.8;
        rx: 5px;
        ry: 5px;
    }

    .grid {
        opacity: 1;
        text-anchor: initial;

        .grid-bg {
            fill: var(--bs-secondary-bg);
        }
    }

    .tick text {
        user-select: none;
    }
}

.review-timeline .loading-label {
    font-size: 15pt;
}

[data-bs-theme="dark"] {
    svg.timeline-d3-chart {
        background-color: var(--bs-secondary-bg);

        .grid-bg {
            fill: var(--bs-body-bg);
        }
    }
}
