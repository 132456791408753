.tags-header {
    background-color: transparent;
    display: flex;
    gap: 3rem;
}

.tags-views {
    grid-area: views;
}

.tags-reps {
    grid-area: reps;
    margin-left: 2rem;
}
