.data-operation-button {
    border-radius: 1.5rem;
    height: 3rem;
    width: 3rem;
    border: 1px solid rgba(127, 127, 127, 0.4);
    background-color: var(--bs-tertiary-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        background-color 0.2s,
        border-color 0.2s;
}

.data-operation-button:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-color: rgba(127, 127, 127, 0.8);
    cursor: pointer;
    transition:
        background-color 0.2s,
        border-color 0.2s;
}

.data-operation-button:disabled:hover {
    background-color: var(--bs-tertiary-bg);
    border-color: rgba(127, 127, 127, 0.2);
    cursor: unset;
}

.data-operation-button:disabled {
    opacity: 0.3;
}

.data-operation-button img {
    height: 2rem;
}
