@use "../../style/mixins.scss" as *;

.data-viz {
    display: grid;
    grid-template-areas:
        "leftbar header rightbar"
        "leftbar main rightbar"
        "leftbar footer rightbar";
    grid-template-columns: 20rem 1fr 20rem;
    grid-template-rows: auto 1fr auto;

    .data-operation-group {
        display: flex;
        gap: 0.5rem;
        justify-items: center;
        align-items: center;
        background-color: var(--bs-secondary-bg);
        border: 1px solid rgba(127, 127, 127, 0.2);
        border-radius: 2rem;
        padding: 0.5rem 0.55rem 0.5rem 1rem;
    }

    .data-operation-group-title {
        font-variant-caps: small-caps;
        margin-right: 1em;
        font-size: 14pt;
        color: #888;
        margin-bottom: 4px;
    }

    .viz-loading {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .viz-header {
        grid-area: header;
        margin: 0 auto;
        margin-top: 1rem;
        display: flex;
    }

    .viz-leftbar {
        grid-area: leftbar;
        display: grid;
        grid-template-rows: auto 1fr auto;
        overflow-y: hidden;

        .viz-filters {
            overflow-y: auto;
        }
    }

    .viz-main {
        grid-area: main;
        overflow-y: auto;
    }

    .viz-rightbar {
        grid-area: rightbar;
        display: grid;
        grid-template-rows: auto 1fr;
        overflow-y: hidden;

        .viz-details {
            overflow-y: auto;
        }
    }

    .viz-footer {
        grid-area: footer;
        display: grid;
        justify-content: center;
        align-content: center;
        margin-left: 0rem;
        margin-right: 0rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
    }
}
