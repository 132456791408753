.copy-button {
    border-radius: 100%;
    transition-property: color, background-color;
    transition-duration: 100ms;

    &:active,
    &:disabled {
        background-color: rgba(var(--bs-secondary-bg-rgb), 1);
        color: var(--bs-body-color);
    }
}
